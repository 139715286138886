import '../css/style.scss'
import { ua } from './modules/ua'
import { alignTargetHeight } from './modules/alignTartgetHeight'
import Swiper from 'swiper/bundle'
import inView from 'in-view'

const klass = {
  active: 'is-active',
  fixed: 'is-fixed',
  view: 'is-view',
  selected: 'is-selected',
  hidden: 'is-hidden',
  eventNone: 'is-eventNone',
  landscape: 'is-landscape',
  current: 'is-current',
  open: 'is-open',
  nav: 'view-nav',
}

class App {
  constructor() {
    this.body = document.querySelector('body')
    this.init()
  }

  init() {
    this.initPages()
    this.setFillHeight()
  }

  initPages() {
    /**
     * 閲覧環境をクラスで付与
     */
    this.body.classList.add(`is-${ua.browser()}`)
    this.body.classList.add(`is-${ua.os()}`)

    /**
     * 読み込み完了後 ローダー非表示化
     */
    window.addEventListener('load', () => {
      document.querySelector('.openLoader').classList.add(klass.hidden)
    })

    // ナビゲーション開閉
    document.querySelector('.navTrigger').addEventListener('click', () => {
      document.querySelector('body').classList.toggle(klass.nav)
    })
    document.querySelector('.nav-close').addEventListener('click', () => {
      document.querySelector('body').classList.toggle(klass.nav)
    })

    // ナビゲーションを押下時に閉じる
    Array.from(document.querySelectorAll('#gNav a')).forEach((trigger) => {
      trigger.addEventListener('click', () => {
        document.querySelector('body').classList.toggle(klass.nav)
      })
    })

    if (this.body.classList.contains('page-index')) {
      /**
       * TOP スライドギャラリー起動
       * @type {Swiper}
       */
      this.swiper = new Swiper('.about-slider', {
        speed: 50000,
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 0,
        allowTouchMove: false,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
      })

      /**
       * TOP サブヘッダー表示
       */
      window.addEventListener('scroll', () => {
        if (window.scrollY > 120) {
          document.querySelector('body').classList.add('is-scroll')
        } else {
          document.querySelector('body').classList.remove('is-scroll')
        }
      })

      /**
       * ラインナップコンテンツ高さ揃え
       */
      let vw = window.innerWidth
      let step = vw > 1023 ? 3 : vw < 768 ? 1 : 2
      alignTargetHeight('.lineup-item__name', step)
      alignTargetHeight('.lineup-item__content', step)

      window.addEventListener('resize', () => {
        vw = window.innerWidth
        step = vw > 1023 ? 3 : vw < 768 ? 1 : 2
        alignTargetHeight('.lineup-item__name', step)
        alignTargetHeight('.lineup-item__content', step)
      })
    }

    /**
     * DOM要素 表示領域への展開
     */
    inView('.inview').on('enter', (el) => {
      el.classList.add('is-view')
    })
    inView.offset(100)

    /**
     * CSS変数 --vh セット
     * ※画面横幅が変わった場合のみ動作
     */
    let vw = window.innerWidth
    window.addEventListener('resize', () => {
      if (vw === window.innerWidth) {
        return
      }
      vw = window.innerWidth
      this.setFillHeight()
    })

    if (document.querySelectorAll('.js-toggleContent')) {
      Array.from(document.querySelectorAll('.js-toggleContent')).forEach((list) => {
        list.children[0].addEventListener('click', () => {
          list.classList.toggle(klass.open)
        })
      })
    }
  }

  /**
   * CSS変数設定 画面高さの取得からvhを設定
   * ※iosの設定向け
   */
  setFillHeight() {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
}

document.addEventListener('DOMContentLoaded', () => {
  window.inazo = new App()
})
