/**
 * 指定要素の高さ揃えモジュール
 * @param elm : ターゲットクラス名
 * @param num : n個ずつ計算
 */
const alignTargetHeight = (elm, num) => {
  const targetArray = Array.from(document.querySelectorAll(elm))
  targetArray.forEach((target, idx) => {
    target.style.height = null
  })
  targetArray.forEach((target, idx) => {
    if (idx === 0 || idx % num === 0) {
      let height = target.clientHeight

      for (let n = idx + 1; n <= idx + num - 1; n++) {
        if (targetArray[n] && height < targetArray[n].clientHeight) {
          height = targetArray[n].clientHeight
        }
      }

      for (let n = idx; n <= idx + num - 1; n++) {
        if (targetArray[n]) {
          targetArray[n].style.height = `${height}px`
        }
      }
    }
  })
}

export { alignTargetHeight }
